// conf
import { conf } from "conf/plone";
// core
import React from "react";
// hooks
import { useState, useEffect } from "react";
import { useFetch } from "usehooks-ts";
// components
import Spinner from "components/spinner";
import Select from "react-select";
import ErrorMessage from "components/error_message";
// blob saver
import { saveAs } from "file-saver";
// context
import { useContext } from "react";
// store
import SiteContext from "store/site";
import { track_download } from "helpers/helpers";

function select2_remap(arr) {
  let tmp = [];
  for (var i = 0; i < arr.length; i++) {
    const cur = arr[i];
    tmp.push({
      value: cur.token,
      label: cur.title,
    });
  }
  tmp.push({
    value: "Others",
    label: "Others",
  });
  return tmp;
}

export default function DownloadForm({ obj, onBtnPress }) {
  const { matomo } = useContext(SiteContext);
  const { data, error } = useFetch(conf.endpoints.customersvocabulary);
  const [form_visible, set_form_visible] = useState(false);
  const [text_visible, set_text_visible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [customers, set_customers] = useState("");
  const [send_btn_enabled, set_send_btn_enabled] = useState(false);
  const [is_dowmloading, set_is_downloading] = useState(false);
  const [dwn_error, set_dwn_error] = useState(false);
  const [file_download_in_progress, set_file_download_in_progress] = useState(false);

  const manage_show_hide = (evt) => {
    evt.preventDefault();
    set_form_visible(true);
    onBtnPress();
  };

  const handle_change = (items) => {
    setSelectedOption(items);
    set_text_visible(items.filter((i) => i.value === "Others").length > 0);
  };

  const postForm = async (url, formData) => {
    const parameterizeArray = (key, arr) => {
      if (arr.length < 1) return "";
      arr = arr.map(encodeURIComponent);
      return "?" + key + ":list=" + arr.join("&" + key + ":list=");
    };

    const c_list = formData.customers_list || [];
    const c_list_serialized = parameterizeArray("customers", c_list);
    const c_other_serialized = encodeURIComponent(formData.customers_other);

    const target =
      c_list_serialized === ""
        ? `${url}?customers_other=${c_other_serialized}`
        : `${url}${c_list_serialized}&customers_other=${c_other_serialized}`;
    //const target = `${url}${c_list_serialized}&customers_other=${c_other_serialized}`;
    const xhr_opts = { redirect: "follow" };

    try {
      const res = await fetch(target, xhr_opts);
      return res;
    } catch (e) {
      console.log("maybe a strange error here?");
      console.log(e);
      return false;
    }
  };

  const handle_form_submit = (evt) => {
    evt.preventDefault();
    set_is_downloading(true);
    /* collect data */
    /*const form_data = {
      customers_other: customers,
      customers_list: selectedOption.map((i) => i.value),
      customers_list_old: selectedOption.map((i) =>
        i.value === "others" ? i.label : `${i.value}|${i.label}`,
      ),
    };*/
    const form_data = can_list_customers
      ? {
          customers_other: customers,
          customers_list: selectedOption.map((i) => i.value),
          customers_list_old: selectedOption.map((i) =>
            i.value === "others" ? i.label : `${i.value}|${i.label}`,
          ),
        }
      : {
          customers_other: customers,
          customers_list: [],
          customers_list_old: [],
        };
    postForm(obj.file.download, form_data).then((result) => {
      if (result && result.redirected && result.redirected === true) {
        track_download(obj, matomo);
        if (result?.url) {
          if (result.status === 200 || result.status === 302) {
            set_is_downloading(false);
            set_file_download_in_progress(true);
            window.open(result.url, "_blank");
            return true;
          }
        }
        /*try {
          result.blob().then((blob) => {
            const _filename =
              obj?.file?.filename !== null ? obj.file.filename : null;
            saveAs(blob, _filename);
            set_is_downloading(false);
          });
        } catch (e) {
          set_is_downloading(false);
          console.log(e);
          set_dwn_error(true);
        }*/
      } else {
        set_is_downloading(false);
        set_dwn_error(true);
      }
    });
  };

  const can_list_customers = obj?.can_list_customers || false;

  useEffect(() => {
    let _send_btn_enabled = false;
    if (can_list_customers) {
      if (text_visible && customers.length > 0) {
        _send_btn_enabled = true;
      } else if (!text_visible && selectedOption?.length > 0) {
        _send_btn_enabled = true;
      } else {
        _send_btn_enabled = false;
      }
    } else {
      if (customers.length > 0) {
        _send_btn_enabled = true;
      }
    }
    set_send_btn_enabled(_send_btn_enabled);
  }, [
    text_visible,
    customers.length,
    can_list_customers,
    selectedOption?.length,
  ]);

  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  /*
   * to be better investigated.
   * Select2 seems to have problems with card styles, making the download form complicated to manage.
   * This patch at least seems to make it work
   */

  const classess =
    form_visible === false ? "container" : "container container-form";

  if(file_download_in_progress === true) return (
    <div className="container flex x-center">
      <p>the file is downlaod check your browser</p>
    </div>
  );

  if(file_download_in_progress === false) return (
    <div className={classess}>
      {form_visible === false && (
        <div className="container flex x-center">
          <a href="/" className="button button-wk1" onClick={manage_show_hide}>
            <i className="icon icon-download icon-small icon-neutral-1-light"></i>
            Download
          </a>
        </div>
      )}

      {form_visible && (
        <div className="">
          <p>
            <small>Before downloading, please fill out this survey</small>
          </p>
          <form>
            {can_list_customers && (
              <div className="">
                <h5>For which customers are you downloading this resource?</h5>
                <p>
                  <small>
                    One or more option are allowed; please select{" "}
                    <em>Others</em> if you don't find what are looking for.
                  </small>
                </p>
                <Select
                  defaultValue={selectedOption}
                  onChange={handle_change}
                  options={select2_remap(data.items)}
                  classNamePrefix="react-select"
                  placeholder="Type the name or the CRM id"
                  className="react-select-container"
                  isMulti={true}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "20em",
                    }),
                  }}
                />
              </div>
            )}
            {(text_visible || !can_list_customers) && (
              <div className="container">
                {can_list_customers && <h5>Which customers you can't find?</h5>}
                {!can_list_customers && (
                  <h5>
                    For which customers are you downloading this resource?
                  </h5>
                )}
                <p>
                  <small>Insert one or more names separated by comma</small>
                </p>
                <textarea
                  className="form-control"
                  rows="3"
                  name="customers_other"
                  placeholder="Customer 1, Customer2, Customer 3"
                  value={customers}
                  onChange={(evt) => set_customers(evt.target.value)}
                ></textarea>
              </div>
            )}
            <div>
              {send_btn_enabled && !is_dowmloading && (
                <button
                  type="submit"
                  className="button button-wk1"
                  onClick={handle_form_submit}
                >
                  <i className="icon icon-download icon-small icon-neutral-1-light"></i>
                  Submit and download
                </button>
              )}
              {send_btn_enabled && is_dowmloading && (
                <div
                  style={{
                    display: "flex",
                    float: "left",
                    marginLeft: "50px",
                    color: "red",
                  }}
                >
                  <span>
                    We are preparing the file, please wait a moment to save it.
                  </span>
                </div>
              )}
              {send_btn_enabled && is_dowmloading && <Spinner />}
              {dwn_error && (
                <span>
                  there seems to have been an error with the download try later.
                </span>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
